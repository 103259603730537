export default {
  book: 'Book your reservation',
  booking: 'Book',
  'booking.choose': 'Choose a capsule',
  'booking.from': 'From',
  'booking.to': 'To',
  'booking.price.free': 'Offered',
  'booking.add': 'Add a booking',
  'booking.next': 'Next',
  'booking.previous': 'Previous',
  'booking.upcoming': 'Upcoming',
  'booking.reservationFor': 'Reservation for',
  'booking.confirmed': 'Booking confirmed!',
  'booking.coupon.currency.mismatch':
    'The coupon is not valid for this capsule',
  'booking.passed': 'Booking passed. Thank you for your visit!',
  'booking.showQrCode': 'Show this QR code to validate your appointment on {{date}}',
  'booking.info': 'This is your unique pass for your capsule.',
  'booking.safe': "Don't share it and keep it safe!",
  'booking.download': 'Download your QR Code',
  'booking.download.step': 'save',
  'booking.location.map': 'Map',
  'booking.location.list': 'List',
  'booking.date.select': 'Select a date',
  'booking.free.title': 'Free session!',
  'booking.free.subtitle': 'Offered by ',
  'booking.payment.coupon': 'Enter your coupon',
  'booking.payment.submit': 'Pay {{amount}} {{currency}}',
  'booking.payment.coupon.required': 'Coupon is required',
  'booking.coupon.notfound': 'Invalid coupon',
  'booking.coupon.notenough': 'Not enough amount',
  'booking.payment.coupon.question': 'Do you have a coupon?',
  'booking.payment.coupon.yes': 'Yes',
  'booking.payment.coupon.no': 'No',
  'booking.coupon.usedup': 'This coupon has been used too many times',
  'booking.payment.success.title': 'Payment successful!',
  'booking.payment.success.message':
    'Congratulations! Your booking has been successfully confirmed.',
  'booking.payment.success.redirect': 'You will be redirected',
  'booking.payment.success.seconds': 'seconds',
  'title.paymentSuccess': 'Payment successful',
  'booking.payment.summary.title': 'RESERVATION / CONFIRMATION',
  'booking.payment.summary.capsule': 'Capsule',
  'booking.payment.summary.date': 'Date',
  'booking.payment.coupon.title': 'Got a coupon?',
  'booking.payment.coupon.description':
    'If you have a coupon, enter it below to get your discount.',
  'booking.payment.coupon.placeholder': 'Enter your coupon code',
  'booking.payment.coupon.verify': 'Verify',
  'booking.payment.coupon.valid': 'Valid coupon!',
  'booking.payment.summary.location': 'Location',
  'booking.payment.coupon.invalid': 'Invalid coupon',
  'booking.payment.coupon.offeredBy': 'Offered by',
  'booking.payment.coupon.remove': 'Remove coupon',
  'booking.style.title': 'Customize your style',
  'booking.style.description': 'Create your avatar\'s unique style',
  'title.bookingStyle': 'Style Customization',
  'booking.no.upcoming': 'No upcoming bookings',
  'booking.no.previous': 'No previous bookings',
  'booking.details': 'Details',
  'booking.paymentDetails': 'Payment Details',
  'booking.paymentDate': 'Payment Date',
  'booking.amountPaid': 'Amount Paid',
  'booking.paymentReference': 'Payment Reference',
  'booking.initialAmount': 'Initial price',
  'booking.sponsoredBy': 'Sponsored by',
  'booking.by': 'by',
  'booking.sponsored': 'sponsored',
  'booking.coupon': 'coupon',
  'booking.reservationPour': 'Reservation for',
  'booking.reservationDu': 'Reservation on',
  'booking.createdOn': 'Taken on',
  'booking.offeredBy': 'Offered by',
  'booking.le': 'on',
  'booking.cancelled': 'Cancelled',
};
