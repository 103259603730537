import React from 'react';
import PreRegister from 'views/auth/preregister/PreRegister';
import Login from 'views/auth/login/Login';
import Collection from 'views/avatar/collection/Collection';
import Register from 'views/auth/register/Register';
import Settings from 'views/settings/Settings';
import DetailsAccount from 'views/settings/AccountDetails/AccountDetails';
import MovieList from 'views/movie/MovieList/MovieList';
import MovieDetails from 'views/movie/MovieDetails/MovieDetails';
import Home from 'views/home/Home';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import App from 'App';
import ProtectedRoute from 'component/ProtectedRoute';
import BookingList from 'views/booking/list/BookingList';
import Booking from 'views/booking/details/Booking';
import BookingLocation from 'views/booking/location/BookingLocation';
import BookingDate from 'views/booking/date/BookingDate';
import BookingPayment from 'views/booking/payment/BookingPayment';
import ResetPassword from 'views/auth/reset/ResetPassword';
import FastPassLogin from 'views/auth/fastPassLogin/FastPass';
import CreateAvatarStyle from 'views/avatar/style/create/CreateAvatarStyle';
import TestBuildYourStyle from 'views/test/TestBuildYourStyle';
import BookingPaymentSuccess from './views/booking/payment/BookingPaymentSuccess/BookingPaymentSuccess';

// Routes de base pour tous les environnements
const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        ),
      },
      { path: '/login', element: <Login /> },
      { path: '/register', element: <PreRegister /> },
      { path: '/auth/complete-registration', element: <Register /> },
      { path: '/auth/reset-password', element: <ResetPassword /> },
      {
        path: '/avatar/collection',
        element: (
          <ProtectedRoute>
            <Collection />
          </ProtectedRoute>
        ),
      },
      {
        path: '/account',
        element: (
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/account/details',
            element: (
              <ProtectedRoute>
                <DetailsAccount />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/movie',
        element: (
          <ProtectedRoute>
            <MovieList />
          </ProtectedRoute>
        ),
      },
      {
        path: '/movie/:idMovie/:idPicture3d',
        element: (
          <ProtectedRoute>
            <MovieDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: '/booking',
        element: (
          <ProtectedRoute>
            <BookingList />
          </ProtectedRoute>
        ),
      },
      {
        path: 'booking/pay/success',
        element: <BookingPaymentSuccess />,
      },
      {
        path: 'booking/pay/cancel',
        element: <Navigate to="/booking" replace />,
      },
      {
        path: '/booking/:idBooking',
        element: (
          <ProtectedRoute>
            <Booking />
          </ProtectedRoute>
        ),
      },
      {
        path: '/booking/new',
        element: (
          <ProtectedRoute>
            <BookingLocation />
          </ProtectedRoute>
        ),
      },
      {
        path: 'booking/new/:idCapsuleLocation',
        element: (
          <ProtectedRoute>
            <BookingDate />
          </ProtectedRoute>
        ),
      },
      {
        path: 'booking/pay/:idCapsuleLocation',
        element: (
          <ProtectedRoute>
            <BookingPayment />
          </ProtectedRoute>
        ),
      },

      { path: '/capsule/login/:idCapsule', element: <FastPassLogin /> },
      {
        path: '/avatar/style/create/:idPicture3D',
        element: (
          <ProtectedRoute>
            <CreateAvatarStyle />
          </ProtectedRoute>
        ),
      },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
];

// Ajouter la route TestBuildYourStyle uniquement en environnement de développement
if (process.env.NODE_ENV === 'development') {
  routes[0].children.push({
    path: '/test/build-your-style',
    element: <TestBuildYourStyle />,
  });
}

export const router = createBrowserRouter(routes);

export default router;
