export default {
  book: 'Réservez votre session',
  booking: 'Réserver',
  'booking.choose': 'Choisissez une capsule',
  'booking.from': 'Du',
  'booking.to': 'au',
  'booking.price.free': 'Offert',
  'booking.reservationFor': 'Réservation pour le',
  'booking.add': 'Ajouter une réservation',
  'booking.next': 'Suivant',
  'booking.previous': 'Précédent',
  'booking.coupon.currency.mismatch':
    "Le coupon n'est pas valide pour cette capsule",
  'booking.upcoming': 'À venir',
  'booking.confirmed': 'Réservation confirmée !',
  'booking.passed': 'Réservation passée. Merci pour votre visite !',
  'booking.showQrCode': 'Présentez ce QR code pour valider votre rendez-vous du {{date}}',
  'booking.info': 'Ceci est votre pass unique pour votre capsule.',
  'booking.safe': 'Ne le partagez pas et gardez-le en sécurité !',
  'booking.download': 'Téléchargez votre QR Code',
  'booking.download.step': 'Enregistrement',
  'booking.location.map': 'Carte',
  'booking.location.list': 'Liste',
  'booking.date.select': 'Sélectionnez une date',
  'booking.free.title': 'Session gratuite !',
  'booking.free.subtitle': 'Offerte par ',
  'booking.payment.coupon': 'Entrez votre coupon',
  'booking.payment.submit': 'Payer {{amount}} {{currency}}',
  'booking.payment.coupon.required': 'Le coupon est requis',
  'booking.coupon.notfound': 'Coupon invalide',
  'booking.coupon.notenough': 'Montant insuffisant',
  'booking.payment.success.title': 'Paiement réussi !',
  'booking.payment.success.message':
    'Félicitations ! Votre réservation a été confirmée avec succès.',
  'booking.payment.success.redirect': 'Vous allez être redirigé',
  'booking.payment.success.seconds': 'secondes',
  'title.paymentSuccess': 'Paiement réussi',
  'booking.payment.coupon.question': 'Avez-vous un coupon ?',
  'booking.payment.coupon.yes': 'Oui',
  'booking.payment.coupon.no': 'Non',
  'booking.coupon.usedup': 'Ce coupon à été utilisé trop de fois',
  'booking.payment.summary.title': 'RESERVATION / CONFIRMATION',
  'booking.payment.summary.capsule': 'Capsule',
  'booking.payment.summary.date': 'Date',
  'booking.payment.coupon.title': 'Vous avez un coupon ?',
  'booking.payment.coupon.description':
    'Si vous avez un coupon, entrez-le ci-dessous pour bénéficier de votre réduction.',
  'booking.payment.coupon.placeholder': 'Entrez votre coupon',
  'booking.payment.coupon.verify': 'Vérifier',
  'booking.payment.coupon.valid': 'Coupon valide !',
  'booking.payment.summary.location': 'Lieu',
  'booking.payment.coupon.invalid': 'Coupon invalide',
  'booking.payment.coupon.offeredBy': 'Offert par',
  'booking.payment.coupon.remove': 'Retirer le coupon',
  'booking.style.title': 'Personnalisez votre style',
  'booking.style.description': 'Créez le style unique de votre avatar',
  'title.bookingStyle': 'Personnalisation du style',
  'booking.no.upcoming': 'Aucune réservation à venir',
  'booking.no.previous': 'Aucune réservation passée',
  'booking.details': 'Détails',
  'booking.paymentDetails': 'Détails du paiement',
  'booking.paymentDate': 'Date du paiement',
  'booking.amountPaid': 'Montant payé',
  'booking.paymentReference': 'Référence du paiement',
  'booking.initialAmount': 'Prix initial',
  'booking.sponsoredBy': 'Sponsorisé par',
  'booking.by': 'par',
  'booking.sponsored': 'sponsorisé',
  'booking.coupon': 'coupon',
  'booking.reservationPour': 'Réservation pour le',
  'booking.reservationDu': 'Réservation du',
  'booking.createdOn': 'Pris le',
  'booking.offeredBy': 'Offert par',
  'booking.le': 'le',
  'booking.cancelled': 'Annulé',
};
