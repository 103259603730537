import React, { useState } from 'react';
import Header from 'component/global/Header/Header';
import COLORS from 'color';
import { useParams } from 'react-router-dom';
import { buildCreatedAvatarStyle } from 'providers/style';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import BuildYourStyle from 'components/BuildYourStyle';
import { StyleSelections } from 'hooks/style/useBuildYourStyle';

const CreateAvatarStyle: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { idPicture3D } = useParams();
  const randomKeyString = Math.random().toString(36).substring(7);

  const handleComplete = async (selections: StyleSelections) => {
    if (!idPicture3D) return;

    setIsSubmitting(true);
    setError(null);

    try {
      // Préparation des IDs pour l'API
      const idStyleAssets = [
        ...selections.eyesIds,
        ...selections.hairIds,
        ...selections.hairColorsIds,
      ].map((id) => parseInt(id.toString(), 10));

      // Appel de l'API pour enregistrer les sélections
      await buildCreatedAvatarStyle(parseInt(idPicture3D, 10), idStyleAssets);

      // Redirection après succès
      window.location.href = '/?bsc=1';
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || 'Une erreur est survenue');
      setIsSubmitting(false);
    }
  };

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: COLORS.GAINSBORO,
      }}
    >
      {isSubmitting ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <BuildYourStyle
          onComplete={handleComplete}
          onError={handleError}
          skipPreview={false}
        />
      )}

      {error ? (
        <Notification
          type="error"
          message={error}
          key={randomKeyString}
        />
      ) : null}
    </div>
  );
};

export default CreateAvatarStyle;
