import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  height: 100%;
  background: ${COLORS.GAINSBORO};
`;

export const Booking = styled.div`
  width: 100%;
`;

export const BookingTime = styled.div<ComponentProps>`
  text-align: center;
  margin-top: ${({ size }) => (size?.isMobile ? '10%' : '3%')};
  & > button:nth-child(2) {
    margin-left: 10px;
  }
`;

export const BookingTimeButton = styled.button<
ComponentProps & { selected?: boolean }
>`
  background: ${({ selected }) => (!selected ? 'white' : COLORS.BLUE_SILKKE)};
  color: ${({ selected }) => (!selected ? COLORS.BLUE_SILKKE : 'white')};
  font-size: 1.2rem;
  display: inline-block;
  border: ${({ selected }) => (!selected ? `1px solid ${COLORS.BLUE_SILKKE}` : 'none')};
  text-align: center;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0 2rem;
  height: 2.5rem;
  &:hover {
    background: ${COLORS.GAINSBORO};
    color: ${COLORS.BLUE_SILKKE};
    border: 1px solid ${COLORS.BLUE_SILKKE};
  }
  transition: background 0.8s ease;
`;

export const NewBooking = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  margin-top: ${({ size }) => (size?.isMobile ? '10%' : '3%')};

  & > h3 {
  }
`;

export const NewBookingButton = styled(Link)<ComponentProps>`
  text-align: center;
  align-self: center;
  font-family: "Urbanist", sans-serif;
  font-size: 1.3rem;
  display: block;
  margin: 0 auto;
  color: ${COLORS.BLUE_SILKKE};
`;

export const BookingList = styled.div<ComponentProps>`
  width: ${({ size }) => (size?.isMobile ? '90%' : '50%')};
  max-height: 50vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  margin: ${({ size }) => (size?.isMobile ? '3%' : '0')} auto;
`;

export const BookingListItem = styled(Link)<ComponentProps & { 'data-isprevious'?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 1rem 0;
  padding: ${({ size }) => (size?.isMobile ? '0.5rem' : '1.5rem')};
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  &[data-isprevious="true"] {
    background: #f5f5f5;
  }
`;

export const BookingListItemQrCode = styled.img<ComponentProps>`
  width: ${({ size }) => (size?.isMobile ? '50%' : '20%')};
`;

export const BookingListItemInfo = styled.div<ComponentProps>`
  flex: 1;
  padding: 0 1rem;
  > div {
    margin: 0.5rem 0;
  }
  display: flex;
  flex-direction: column;
`;

export const BookingListItemInfoDate = styled.div<ComponentProps & { hasPicture3D?: boolean, 'data-isprevious'?: boolean }>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: ${COLORS.GREY};
  display: flex;
  align-items: center;

  &[data-isprevious="true"] {
    color: ${({ hasPicture3D }) => (hasPicture3D ? '#4CAF50' : '#FF5252')};
  }
`;

export const BookingListItemInfoAddress = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: ${COLORS.GREY};
`;

export const BookingListItemInfoPrice = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: #abacac;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CompanyLogo = styled.img<ComponentProps>`
  width: ${({ size }) => (size?.isMobile ? '1.5rem' : '2rem')};
  height: ${({ size }) => (size?.isMobile ? '1.5rem' : '2rem')};
  object-fit: contain;
  border-radius: 4px;
`;

export const CancelledBadge = styled.div`
  background-color: #FF5252;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  font-family: "Urbanist", sans-serif;
  line-height: 1;
`;

export const EmptyMessage = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1.2rem' : '1.5rem')};
  color: ${COLORS.GREY};
  text-align: center;
  margin-top: 2rem;
`;
