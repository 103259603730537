import React from 'react';
import COLORS from 'color';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import useBuildYourStyle, { StyleSelections } from 'hooks/style/useBuildYourStyle';
import CoreEyes from './core/CoreEyes';
import CoreHair from './core/CoreHair';

export type BuildYourStyleProps = {
  onComplete?: (selections: StyleSelections) => void;
  initialStep?: 'style' | 'hair';
  skipPreview?: boolean;
  onError?: (error: string) => void;
  containerStyle?: React.CSSProperties;
};

/**
 * Composant réutilisable pour le processus de personnalisation de style
 * Permet à l'utilisateur de sélectionner les yeux, les cheveux et les couleurs de cheveux
 * Retourne les ID sélectionnés via le callback onComplete
 * S'affiche en plein écran (overlay) pour une expérience immersive
 */
const BuildYourStyle: React.FC<BuildYourStyleProps> = ({
  onComplete,
  initialStep = 'style',
  skipPreview = false,
  onError,
  containerStyle,
}) => {
  const [error, setError] = React.useState<string | null>(null);
  const randomKeyString = Math.random().toString(36).substring(7);

  // Utiliser notre hook personnalisé
  const {
    globalStep,
    setEyesIds,
    setHairColorsIds,
    setHairIds,
    mustSkipPreview,
    changeStepHairToStyle,
  } = useBuildYourStyle({
    onComplete,
    initialStep,
    skipPreview,
  });

  // Gestion des erreurs
  React.useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  // Style pour le positionnement en overlay (plein écran)
  const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    background: COLORS.GAINSBORO,
    overflow: 'hidden',
    ...containerStyle,
  };

  // Mapping des étapes aux composants correspondants
  const stepToComponent: { [K in typeof globalStep]: JSX.Element } = {
    style: <CoreEyes setEyesIds={setEyesIds as any} skipPreview={mustSkipPreview} />,
    hair: <CoreHair
      setHairColors={setHairColorsIds as any}
      setHair={setHairIds as any}
      changeStepHairToStyle={changeStepHairToStyle}
    />,
    submission: <LoadingSpinner shouldBeCenter />,
  };

  // Effect pour empêcher le scroll du body quand l'overlay est actif
  React.useEffect(() => {
    // Désactiver le scroll sur le body
    document.body.style.overflow = 'hidden';
    
    // Nettoyer en quittant
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div style={overlayStyle}>
      {stepToComponent[globalStep]}
      {error ? (
        <Notification
          type="error"
          message={error}
          key={randomKeyString}
        />
      ) : null}
    </div>
  );
};

export default BuildYourStyle;
