import React, { useState } from 'react';
import preview from 'assets/img/create_style_preview.png';
import Button from 'component/Button';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'component/LoadingSpinner';
import AssetsBuilder from 'views/avatar/style/create/eyes/builder/EyesBuilder';
import * as S from 'views/avatar/style/create/eyes/EyesStyle';

const GetStarted: React.FC<{
  setDisplayPreview: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setDisplayPreview }) => {
  const responsive = useResponsive();
  const { t } = useTranslation();
  return (
    <S.GetStartedContainer>
      <S.Main>
        <S.PreviewImageWrapper>
          <S.PreviewImage
            src={preview}
            alt="digital human background"
          />
          <S.PreviewTextCoveringImage>
            {t('digital-human.create.preview')}
          </S.PreviewTextCoveringImage>
        </S.PreviewImageWrapper>
        <S.Action size={responsive}>
          <Button
            height="5vh"
            width={responsive.isDesktop ? '10%' : undefined}
            style={{ marginTop: '4%' }}
            disabled={false}
            onClick={() => setDisplayPreview(false)}
          >
            {t('digital-human.create.preview.next')}
          </Button>
        </S.Action>
      </S.Main>
    </S.GetStartedContainer>
  );
};

type CoreEyesProps = {
  setEyesIds: React.Dispatch<React.SetStateAction<any>>;
  skipPreview?: boolean;
};

/**
 * Version autonome du composant Eyes qui ne dépend pas de l'existence d'un idPicture3D
 * Ce composant peut être utilisé dans n'importe quel contexte où on veut simplement
 * sélectionner des éléments de style pour les yeux.
 */
const CoreEyes: React.FC<CoreEyesProps> = ({
  setEyesIds,
  skipPreview = false,
}) => {
  const [displayPreview, setDisplayPreview] = useState<boolean>(true);

  if (displayPreview && !skipPreview) {
    return <GetStarted setDisplayPreview={setDisplayPreview} />;
  }
  return <AssetsBuilder setEyesIds={setEyesIds} />;
};

export default CoreEyes;
