import React, { useEffect } from 'react';

import useResponsive from 'hooks/useResponsive';
import { createFreeBooking } from 'providers/booking';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import { buildStyle } from 'providers/style';
import { StyleSelections } from 'hooks/style/useBuildYourStyle';
import { useTranslation } from 'react-i18next';
import * as style from './BookingFreeStyle';

interface BookingFreeProps {
  capsule: any;
  styleSelections: StyleSelections | null;
}

const BookingFree: React.FC<BookingFreeProps> = ({
  capsule,
  styleSelections,
}) => {
  const { t } = useTranslation();
  const date = new URLSearchParams(window.location.search).get('date');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const randomKeyString = Math.random().toString(36).substring(7);
  useEffect(() => {
    document.title = t('title.freeBooking');
  }, []);

  const booking = () => {
    setLoading(true);
    if (date) {
      createFreeBooking(capsule.idCapsuleLocation, date)
        .then((response) => {
          if (response.data.success) {
            // Si nous avons des sélections de style et que c'est un type qui nécessite buildStyle
            if (styleSelections && capsule.idPicture3DType === 2) {
              // Préparation des IDs pour l'API
              const idStyleAssets = [
                ...styleSelections.eyesIds,
                ...styleSelections.hairIds,
                ...styleSelections.hairColorsIds,
              ].map((id) => parseInt(id.toString(), 10));

              // Appel de l'API pour enregistrer les sélections
              buildStyle(
                parseInt(response.data.booking.idBooking, 10),
                idStyleAssets,
              )
                .then(() => {
                  // Redirection après le buildStyle
                  const fastpass = new URLSearchParams(
                    window.location.search,
                  ).get('fastpass');
                  const href = `/booking/${response.data.booking.idBooking}${
                    fastpass === 'true' ? '?fastpass=true' : ''
                  }`;
                  window.location.href = href;
                })
                .catch((error) => {
                  setErrorMessage(
                    error.response?.data?.message
                      || 'Une erreur est survenue lors de la personnalisation',
                  );
                  setLoading(false);
                });
            } else {
              // Pas de buildStyle nécessaire, redirection directe
              const fastpass = new URLSearchParams(window.location.search).get(
                'fastpass',
              );
              const href = `/booking/${response.data.booking.idBooking}${
                fastpass === 'true' ? '?fastpass=true' : ''
              }`;
              window.location.href = href;
            }
          }
          if (!styleSelections) {
            setLoading(false);
          }
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setLoading(false);
        });
    }
  };
  const screenSize = useResponsive();
  return (
    <style.Container size={screenSize}>
      <style.Title>{t('booking.free.title')}</style.Title>
      <style.SubTitle>{t('booking.free.subtitle')}</style.SubTitle>

      {capsule.location?.company?.logo ? (
        <style.Company
          size={screenSize}
          src={capsule.location?.company?.logo}
        />
      ) : (
        <style.InfoCompany size={screenSize}>{capsule.libel}</style.InfoCompany>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <style.Button
          size={screenSize}
          onClick={booking}
        >
          {t('booking.next')}
        </style.Button>
      )}
      {errorMessage && (
        <Notification
          type="error"
          key={randomKeyString}
          message={errorMessage}
        />
      )}
    </style.Container>
  );
};

export default BookingFree;
