import React, {
  useEffect, useState, ChangeEvent, FormEvent, 
} from 'react';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import LoadingSpinner from 'component/LoadingSpinner';
import Button from 'component/Button';
import { preparePaidBooking } from 'providers/booking';
import { verifyCoupon } from 'providers/coupon';
import Notification from 'component/Notification';
import { buildStyle } from 'providers/style';
import { StyleSelections } from 'hooks/style/useBuildYourStyle';
import * as style from './BookingPaymentFormStyle';

interface ScreenSize {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface FormInputs {
  couponCode: string;
}

interface Company {
  id: number;
  name: string;
  logo: string;
  webSite: string;
}

interface CouponResponse {
  success: boolean;
  message: string | null;
  amount: string;
  currency: string;
  code: string;
  validUntil?: string;
  restToUse?: number;
  company?: Company;
}

interface BookingPaymentFormProps {
  capsule: any;
  styleSelections: StyleSelections | null;
}

const BookingPaymentForm: React.FC<BookingPaymentFormProps> = ({
  capsule,
  styleSelections,
}) => {
  const screenSize = useResponsive() as ScreenSize;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isVerifyingCoupon, setIsVerifyingCoupon] = useState<boolean>(false);
  const [validatedCoupon, setValidatedCoupon] = useState<string>('');
  const [couponData, setCouponData] = useState<CouponResponse | null>(null);
  const [showCouponInput, setShowCouponInput] = useState<boolean>(false);
  const { t } = useTranslation();
  const { register, handleSubmit, watch } = useForm<FormInputs>();
  const couponCode = watch('couponCode');

  useEffect(() => {
    document.title = t('title.payment');
  }, []);

  const date = new URLSearchParams(window.location.search).get('date');

  const priceFormatted = parseFloat(capsule.price).toFixed(2);
  const formattedPrice = priceFormatted.endsWith('.00')
    ? priceFormatted.slice(0, -3)
    : priceFormatted;

  const getCurrencySymbol = (currency: string) => {
    switch (currency?.toUpperCase()) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$US';
      default:
        return currency;
    }
  };

  const randomKeyString = Math.random().toString(36).substring(7);

  const handleVerifyCoupon = async () => {
    if (!couponCode) return;
    setIsVerifyingCoupon(true);
    try {
      const response = await verifyCoupon(
        couponCode,
        capsule.idCapsuleLocation,
      );
      const message = t('booking.payment.coupon.valid');
      if (typeof message === 'string') {
        setSuccessMessage(message);
      }
      setValidatedCoupon(couponCode);
      setCouponData(response.data);
    } catch (error: any) {
      const message = t(
        error.response?.data?.message || 'booking.payment.coupon.invalid',
      );
      if (typeof message === 'string') {
        setErrorMessage(message);
      }
      setValidatedCoupon('');
      setCouponData(null);
    } finally {
      setIsVerifyingCoupon(false);
    }
  };

  const onSubmitCoupon = handleSubmit(() => {
    handleVerifyCoupon();
  });

  const submit = () => {
    setLoading(true);
    if (date) {
      const idStyleAssets = styleSelections && capsule.idPicture3DType === 2
        ? [
          ...styleSelections.eyesIds,
          ...styleSelections.hairIds,
          ...styleSelections.hairColorsIds,
        ].map((id) => parseInt(id.toString(), 10))
        : undefined;

      preparePaidBooking(
        capsule.idCapsuleLocation,
        date,
        validatedCoupon,
        idStyleAssets,
      )
        .then((response: any) => {
          if (response.data.booking) {
            const fastpass = new URLSearchParams(
              window.location.search,
            ).get('fastpass');
            const href = `/booking/${response.data.booking.idBooking}${
              fastpass === 'true' ? '?fastpass=true' : ''
            }`;
            window.location.href = href;
          } else {
            window.location.href = response.data.url;
          }
          setLoading(false);
        })
        .catch((error: any) => {
          const message = t(error.response.data.message);
          if (typeof message === 'string') {
            setErrorMessage(message);
          }
          setLoading(false);
        });
    }
  };

  const handleRemoveCoupon = () => {
    setValidatedCoupon('');
    setCouponData(null);
    setSuccessMessage('');
  };

  return (
    <style.Container>
      <style.Form size={screenSize}>
        <style.BookingSummaryCard
          size={screenSize}
          style={{
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            padding: '24px',
          }}
        >
          <style.SummaryTitle
            size={screenSize}
            style={{ textAlign: 'center' }}
          >
            {t('booking.payment.summary.title')}
          </style.SummaryTitle>

          <style.SummaryText
            size={screenSize}
            style={{ textAlign: 'center' }}
          >
            {capsule.libel}
          </style.SummaryText>

          {capsule.location && (
            <style.SummaryText
              size={screenSize}
              style={{ textAlign: 'center' }}
            >
              {capsule.location.libel}
              {' | '}
              {capsule.location.address}
              {'  '}
              {capsule.location.zipCode}
              {'  '}
              {capsule.location.city}
            </style.SummaryText>
          )}

          <style.SummaryText
            size={screenSize}
            style={{ textAlign: 'center' }}
          >
            {new Date(date || '').toLocaleDateString()}
          </style.SummaryText>

          <style.Divider />

          <style.PriceText size={screenSize}>
            {couponData ? (
              <>
                <style.StrikethroughPrice size={screenSize}>
                  {formattedPrice} 
                  {' '}
                  {getCurrencySymbol(capsule.currency)}
                </style.StrikethroughPrice>
                <style.PriceArrow>→</style.PriceArrow>
                <style.DiscountedPrice size={screenSize}>
                  {Math.max(
                    0,
                    parseFloat(formattedPrice)
                      - (parseFloat(couponData.amount) || 0),
                  )}
                  {' '}
                  {getCurrencySymbol(couponData.currency)}
                </style.DiscountedPrice>
              </>
            ) : (
              <>
                {formattedPrice} 
                {' '}
                {getCurrencySymbol(capsule.currency)}
              </>
            )}
          </style.PriceText>

          <style.SummaryText
            size={screenSize}
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => setShowCouponInput(!showCouponInput)}
          >
            {t('booking.payment.coupon.title')}
          </style.SummaryText>

          {showCouponInput && (
            <style.CouponSection
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {couponData ? (
                <>
                  <style.CouponChip
                    size={screenSize}
                    style={{ margin: '0 auto' }}
                  >
                    <style.CouponChipText size={screenSize}>
                      {couponData.code}
                    </style.CouponChipText>
                    <style.RemoveCouponButton
                      onClick={handleRemoveCoupon}
                      type="button"
                      aria-label={
                        t('booking.payment.coupon.remove') || 'Remove coupon'
                      }
                    >
                      ×
                    </style.RemoveCouponButton>
                  </style.CouponChip>
                  {couponData.company && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <style.CompanyContainer>
                        <style.OfferedByText>
                          {t('booking.payment.coupon.offeredBy')}
                        </style.OfferedByText>
                        {couponData.company.logo ? (
                          <style.CompanyLogo
                            src={couponData.company.logo}
                            alt={couponData.company.name}
                          />
                        ) : (
                          <style.CompanyName>
                            {couponData.company.name}
                          </style.CompanyName>
                        )}
                      </style.CompanyContainer>
                    </div>
                  )}
                </>
              ) : (
                <style.FormGroupInput
                  size={screenSize}
                  onSubmit={onSubmitCoupon}
                >
                  <style.FI
                    screenState={screenSize}
                    {...register('couponCode')}
                    placeholder={t('booking.payment.coupon.placeholder') || ''}
                    type="text"
                  />
                  <style.FormButton
                    size={screenSize}
                    onClick={onSubmitCoupon}
                    type="button"
                    disabled={isVerifyingCoupon || !couponCode}
                  >
                    {isVerifyingCoupon ? (
                      <LoadingSpinner small />
                    ) : (
                      t('booking.payment.coupon.verify')
                    )}
                  </style.FormButton>
                </style.FormGroupInput>
              )}
            </style.CouponSection>
          )}

          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button
              onClick={submit}
              width="100%"
              height={screenSize.isMobile ? '3rem' : '3.5rem'}
              disabled={false}
            >
              {couponData
              && Math.max(
                0,
                parseFloat(formattedPrice)
                  - (parseFloat(couponData.amount) || 0),
              ) === 0
                ? t('booking')
                : t('booking.payment.submit', {
                  amount: couponData
                    ? Math.max(
                      0,
                      parseFloat(formattedPrice)
                            - (parseFloat(couponData.amount) || 0),
                    )
                    : formattedPrice,
                  currency: getCurrencySymbol(capsule.currency),
                })}
            </Button>
          )}
        </style.BookingSummaryCard>
      </style.Form>

      {errorMessage && (
        <Notification
          key={`error-${randomKeyString}`}
          message={errorMessage}
          type="error"
        />
      )}
      {successMessage && (
        <Notification
          key={`success-${randomKeyString}`}
          message={successMessage}
          type="success"
        />
      )}
    </style.Container>
  );
};

export default BookingPaymentForm;
