import { request } from './request';

export const checkOwner = async (idPicture3D: number) => {
  const owner = await request(true).get(
    `/style/create/check?idPicture3D=${idPicture3D}`,
  );
  return owner;
};

export const getEyesForm = async () => {
  const assets = await request(true).get('/style/create/form/eyes');
  return assets;
};
export const getHairForm = async () => {
  const assets = await request(true).get('/style/create/form/hair');
  return assets;
};
export const getStyleAssetsPreview = async (previews: Array<any>) => {
  const previewsUrl = previews
    .map((preview: any) => (typeof preview === 'string'
      ? `previews[]=${preview}`
      : `previews[${preview.libel}]=${preview.path}`))
    .join('&');

  const previewUrl = await request(true).get(
    `/style/create/form/preview?${previewsUrl}`,
  );
  return previewUrl;
};

export const getHairColorPreview = async () => {
  const previews = await request(true).get('/style/create/form/hair/color');
  return previews;
};

export const buildCreatedAvatarStyle = async (
  idPicture3D: number,
  ids: Array<number>,
) => {
  const style = await request(true).put('/style/create/avatar', {
    idPicture3D,
    idStyleAssets: ids,
  });
  return style;
};

export const buildStyle = async (idBooking: number, ids: Array<number>) => {
  const style = await request(true).put('/style/create', {
    idBooking,
    idStyleAssets: ids,
  });

  return style;
};
