import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import Header from 'component/global/Header/Header';

import LoadingSpinner from 'component/LoadingSpinner';
import { getCapsuleLocationDetails } from 'providers/capsuleLocation';
import { useParams } from 'react-router-dom';
import useResponsive from 'hooks/useResponsive';
import Button from 'component/Button';
import Footer from 'component/global/Footer/Footer';
import { useTranslation } from 'react-i18next';
import * as style from './BookingDateStyle';

const BookingDate: React.FC = () => {
  const { t } = useTranslation();
  const responsive = useResponsive();
  const { isMobile } = responsive;
  const screenSize = responsive;
  const [availableDate, setAvailableDate] = useState<Array<Date>>([
    new Date(),
    new Date(),
  ]);
  useEffect(() => {
    document.title = t('title.bookingDate');
  }, []);
  const [date, setDate] = useState<Date>(new Date());
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const [loading, setLoading] = useState<boolean>(true);
  const { idCapsuleLocation } = useParams();
  const handleDateChange = (value: any) => {
    setDate(value);
  };
  const checkDisabledTile = (tile: any) => {
    const calendar = new Date(tile.date);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const end = new Date(availableDate[1]);
    return calendar < yesterday || calendar > end;
  };

  useEffect(() => {
    if (idCapsuleLocation) {
      getCapsuleLocationDetails(idCapsuleLocation).then((res) => {
        setAvailableDate([res.data.location.begin, res.data.location.end]);
        setLoading(false);
      });
    }
  }, []);
  return (
    <style.Container>
      <Header
        title="Book"
        subTitle="Capsule"
      />
      {loading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <>
          <style.Main size={screenSize}>
            <style.Title size={screenSize}>
              {t('booking.date.select')}
            </style.Title>
            <style.CalendarComp
              size={screenSize}
              onChange={handleDateChange}
              value={date}
              tileDisabled={checkDisabledTile}
            />
            <style.ButtonLink
              size={screenSize}
              to={`/booking/pay/${idCapsuleLocation}?date=${formattedDate}`}
            >
              {t('booking')}
            </style.ButtonLink>
          </style.Main>

          {isMobile && (
            <Footer
              title={t('booking.date.select')}
              step={2}
              totalStep={4}
            />
          )}
        </>
      )}
    </style.Container>
  );
};

export default BookingDate;
