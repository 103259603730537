import React, { useEffect, useState } from 'react';
import useResponsive from 'hooks/useResponsive';
import { Tooltip } from '@mui/material';
import * as S from './PaletteStyle';

type ColorPaletteSliderProps = {
  colors: { libel: string; idStyleAsset: number }[];
  type: 'img' | 'color';
  style?: React.CSSProperties;
  displayCount: number;
  setColor?: React.Dispatch<React.SetStateAction<any>> | null;
};

const ColorPaletteSlider: React.FC<ColorPaletteSliderProps> = ({
  colors,
  style,
  type,
  displayCount,
  setColor,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState('');
  useEffect(() => {
    if (displayCount <= 1) {
      setSelectedColor(colors[startIndex].libel);
      if (setColor) {
        setColor(colors[startIndex].idStyleAsset || colors[startIndex]);
      }
    }
  }, [displayCount, startIndex]);
  const nextColors = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % colors.length);
  };

  const prevColors = () => {
    setStartIndex(
      (prevIndex) => (prevIndex - 1 + colors.length) % colors.length,
    );
  };
  const handleSelect = (color: any) => {
    if (setColor) {
      setColor(color.idStyleAsset || color);
    }
    setSelectedColor(color.libel);
  };
  const responsive = useResponsive();
  const displayedColors = colors
    .slice(startIndex, startIndex + displayCount)
    .concat(
      colors.slice(0, Math.max(startIndex + displayCount - colors.length, 0)),
    );
  return (
    <S.Palette style={{ ...style }}>
      {colors.length > displayCount && (
        <S.SliderButton size={responsive} onClick={prevColors}>
          &lt;
        </S.SliderButton>
      )}
      <S.ColorWrapper>
        <Tooltip title="Add" placement="top">
          {displayedColors && (
            <>
              {displayedColors.map((color, index) => (type === 'color' ? (
                <S.ColorPin
                  size={responsive}
                  key={index as number}
                  color={color.libel}
                  selected={selectedColor === color.libel}
                  onClick={() => handleSelect(color)}
                />
              ) : (
                <S.ImageColor
                  size={responsive}
                  key={index as number}
                  onClick={() => handleSelect(color)}
                  selected={selectedColor === color.libel}
                  url={color.libel}
                />
              )))}
            </>
          )}
        </Tooltip>
      </S.ColorWrapper>
      {colors.length > displayCount && (
        <S.SliderButton size={responsive} onClick={nextColors}>
          &gt;
        </S.SliderButton>
      )}
    </S.Palette>
  );
};

export default ColorPaletteSlider;
