import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'component/global/Header/Header';
import LoadingSpinner from 'component/LoadingSpinner';
import Footer from 'component/global/Footer/Footer';
import useResponsive from 'hooks/useResponsive';
import { getCapsuleLocationDetails } from 'providers/capsuleLocation';
import { useTranslation } from 'react-i18next';
import { StyleSelections } from 'hooks/style/useBuildYourStyle';
import BuildYourStyle from 'components/BuildYourStyle/BuildYourStyle';
import Notification from 'component/Notification';
import * as style from './BookingPaymentStyle';
import BookingPaymentForm from './BookingPaymentForm/BookingPaymentForm';
import BookingFree from './BookingFree/BookingFree';

const BookingPayment: React.FC = () => {
  const { t } = useTranslation();
  const { idCapsuleLocation } = useParams();
  const [loading, setLoading] = useState(true);
  const [capsuleLocation, setCapsuleLocation] = useState<any>({});
  const [isSubmittingStyle, setIsSubmittingStyle] = useState<boolean>(false);
  const [showStyleStep, setShowStyleStep] = useState<boolean>(false);
  const [styleComplete, setStyleComplete] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [styleSelections, setStyleSelections] = useState<StyleSelections | null>(null);
  const screenSize = useResponsive();
  const date = new URLSearchParams(window.location.search).get('date');
  const randomKeyString = Math.random().toString(36).substring(7);

  useEffect(() => {
    if (
      // eslint-disable-next-line radix
      (idCapsuleLocation && typeof parseInt(idCapsuleLocation) !== 'number')
      || !date
    ) {
      window.location.href = '/';
    }

    if (idCapsuleLocation) {
      getCapsuleLocationDetails(idCapsuleLocation).then((response) => {
        setCapsuleLocation(response.data.location);
        // Si idPicture3DType est 2, nous devons montrer l'étape de style
        setShowStyleStep(response.data.location?.idPicture3DType === 2);
        setLoading(false);
      });
    }
  }, []);

  const handleStyleComplete = async (selections: StyleSelections) => {
    setIsSubmittingStyle(true);
    setError(null);

    try {
      // Au lieu d'appeler buildStyle, on stocke les sélections pour une utilisation ultérieure
      setStyleSelections(selections);
      
      // Marquer l'étape style comme terminée
      setStyleComplete(true);
      setShowStyleStep(false);
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || 'Une erreur est survenue');
    } finally {
      setIsSubmittingStyle(false);
    }
  };

  const handleStyleError = (errorMessage: string) => {
    setError(errorMessage);
  };

  // Déterminer le numéro d'étape pour le footer
  const getStepNumber = () => {
    if (capsuleLocation?.idPicture3DType === 2 && !styleComplete) {
      return 3; // Étape de style
    }
    return capsuleLocation?.idPicture3DType === 2 ? 4 : 3; // Étape de paiement
  };

  // Déterminer le nombre total d'étapes
  // eslint-disable-next-line arrow-body-style
  const getTotalSteps = () => {
    return capsuleLocation?.idPicture3DType === 2 ? 5 : 4;
  };

  // Afficher le formulaire de paiement approprié
  const renderPaymentForm = () => {
    if (capsuleLocation?.price && capsuleLocation.price !== '0.00') {
      return <BookingPaymentForm capsule={capsuleLocation} styleSelections={styleSelections} />;
    }
    return <BookingFree capsule={capsuleLocation} styleSelections={styleSelections} />;
  };

  if (loading) {
    return (
      <style.Container>
        <Header
          title="Book"
          subTitle="Capsule"
          desktopLinks={
            !new URLSearchParams(window.location.search).get('fastpass')
          }
        />
        <LoadingSpinner shouldBeCenter />
      </style.Container>
    );
  }

  return (
    <style.Container>
      {/* Afficher BuildYourStyle en overlay si nécessaire */}
      {showStyleStep && capsuleLocation?.idPicture3DType === 2 && (
        <BuildYourStyle
          onComplete={handleStyleComplete}
          onError={handleStyleError}
          skipPreview={false}
        />
      )}

      {/* Afficher le reste de l'interface uniquement si BuildYourStyle n'est pas visible */}
      {!showStyleStep && (
        <>
          <Header
            title="Book"
            subTitle="Capsule"
            desktopLinks={
              !new URLSearchParams(window.location.search).get('fastpass')
            }
          />

          <style.Main size={screenSize}>
            {isSubmittingStyle ? (
              <LoadingSpinner shouldBeCenter />
            ) : (
              renderPaymentForm()
            )}
          </style.Main>

          <Footer
            title={t('book')}
            step={getStepNumber()}
            totalStep={getTotalSteps()}
          />
        </>
      )}

      {error && (
        <Notification
          type="error"
          message={error}
          key={randomKeyString}
        />
      )}
    </style.Container>
  );
};

export default BookingPayment;
