import Header from 'component/global/Header/Header';
import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import { getUserBookings } from 'providers/booking';
import LoadingSpinner from 'component/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';

import FailedImage from 'assets/img/failed.png';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as style from './BookingListStyle';

const List: React.FC<any> = ({ bookList, isPrevious }: any) => {
  const { t } = useTranslation();
  const screenSize = useResponsive();

  // Add a method to check if a booking is cancelled
  const isBookingCancelled = (booking: any): boolean => booking.status === 9;

  const getFreePriceDisplay = (
    sponsorName: string,
    dateCreation?: string,
  ): JSX.Element => (
    <span>
      {`${t('booking.payment.coupon.offeredBy')} ${sponsorName}`}
      {dateCreation
        && ` ${t('booking.le')} ${new Date(dateCreation).toLocaleDateString(
          'fr-FR',
        )}`}
    </span>
  );

  const getFullyCoveredPriceDisplay = (booking: any): JSX.Element => {
    const { transaction } = booking;
    const originalPrice = transaction.price
      ? `${transaction.price} ${transaction.currency}`
      : '';
    const sponsorName = transaction.coupon?.company?.name || t('booking.coupon');
    return (
      <span>
        {`${t('booking.payment.coupon.offeredBy')} ${sponsorName}`}
        {` ${t('booking.le')} ${new Date(
          booking.dateCreation,
        ).toLocaleDateString('fr-FR')} (${originalPrice} → 0 ${transaction.currency})`}
      </span>
    );
  };

  const getPartiallyCoveredPriceDisplay = (booking: any): JSX.Element => {
    const { transaction } = booking;
    const sponsorName = transaction.coupon?.company?.name || t('booking.coupon');
    const originalPrice = transaction.price || transaction.payAmount;
    return (
      <span>
        {`${t('booking.sponsoredBy')} ${sponsorName}`}

        {` ${t('booking.le')} ${new Date(
          booking.dateCreation,
        ).toLocaleDateString('fr-FR')} (${originalPrice} ${transaction.currency
        } → ${transaction.payAmount} ${transaction.currency})`}

      </span>
    );
  };

  const getRegularPriceDisplay = (booking: any): JSX.Element => {
    const { transaction } = booking;
    return (
      <span>
        {`${transaction.payAmount} ${transaction.currency} - ${t(
          'booking.createdOn',
        )} ${new Date(booking.dateCreation).toLocaleDateString('fr-FR')}`}

      </span>
    );
  };

  const getBookingPriceDisplay = (booking: any): JSX.Element => {
    if (booking.transaction) {
      const { transaction } = booking;
      const isFullyCovered = parseFloat(transaction.payAmount) === 0 && transaction.couponAmount;
      const isPartiallyCovered = parseFloat(transaction.couponAmount) > 0;

      if (isFullyCovered) return getFullyCoveredPriceDisplay(booking);
      if (isPartiallyCovered) return getPartiallyCoveredPriceDisplay(booking);
      return getRegularPriceDisplay(booking);
    }

    const isFreeBooking = booking.capsule_location?.price === '0.00'
      || parseFloat(booking.price) === 0;
    if (isFreeBooking) {
      const sponsorName = booking.capsule_location?.company?.name;
      const sponsorLogo = booking.capsule_location?.company?.logo;
      return getFreePriceDisplay(sponsorName, booking.dateCreation);
    }

    return (
      <span>
        {`${booking.price} ${booking.capsule_location?.currency || ''} - ${t(
          'booking.createdOn',
        )} ${new Date(booking.dateCreation).toLocaleDateString('fr-FR')}`}

      </span>
    );
  };

  return bookList.map((booking: any) => {
    const dateFormatted = booking.dateBooking
      ? `${t(
        isPrevious ? 'booking.reservationDu' : 'booking.reservationPour',
      )} ${new Date(booking.dateBooking).toLocaleDateString('fr-FR')}`

      : '';

    const address = booking.address && booking.zipCode && booking.city
      ? `${booking.address} ${booking.zipCode} ${booking.city}`

      : '';

    const price = getBookingPriceDisplay(booking);
    const src = booking.qrcode ? `${booking.qrcode}` : FailedImage;

    return isPrevious ? (
      <style.BookingListItem
        as="div"
        key={booking.idBooking}
        data-isprevious={isPrevious}
      >
        <style.BookingListItemInfo size={screenSize}>
          <style.BookingListItemInfoDate
            size={screenSize}
            hasPicture3D={booking.hasPicture3D}
            data-isprevious={isPrevious}
          >
            {dateFormatted}
            {isBookingCancelled(booking) && (
              <style.CancelledBadge>{t('booking.cancelled')}</style.CancelledBadge>
            )}
          </style.BookingListItemInfoDate>
          <style.BookingListItemInfoAddress size={screenSize}>
            {booking.capsule_location?.libel}
          </style.BookingListItemInfoAddress>
          <style.BookingListItemInfoAddress size={screenSize}>
            {address}
          </style.BookingListItemInfoAddress>
          <style.BookingListItemInfoPrice size={screenSize}>
            {price}
          </style.BookingListItemInfoPrice>
        </style.BookingListItemInfo>
      </style.BookingListItem>
    ) : (
      <style.BookingListItem
        to={`/booking/${booking.idBooking}`}
        key={booking.idBooking}
        data-isprevious={isPrevious}
      >
        <style.BookingListItemInfo size={screenSize}>
          <style.BookingListItemInfoDate
            size={screenSize}
            hasPicture3D={booking.hasPicture3D}
            data-isprevious={isPrevious}
          >
            {dateFormatted}
          </style.BookingListItemInfoDate>
          <style.BookingListItemInfoAddress size={screenSize}>
            {booking.capsule_location?.libel}
          </style.BookingListItemInfoAddress>
          <style.BookingListItemInfoAddress size={screenSize}>
            {address}
          </style.BookingListItemInfoAddress>
          <style.BookingListItemInfoPrice size={screenSize}>
            {price}
          </style.BookingListItemInfoPrice>
        </style.BookingListItemInfo>
        <ChevronRight
          sx={{
            color: '#666',
            marginLeft: 'auto',
            marginRight: '16px',
            fontSize: '28px',
            fontWeight: 'bold',
          }}
        />
      </style.BookingListItem>
    );
  });
};

const BookingList: React.FC = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const navigate = useNavigate();
  const [upcomingBookingList, setUpcomingBookingList] = React.useState<any[]>([]);
  const [previousBookingList, setPreviousBookingList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [bookingTime, setBookingTime] = React.useState('upcoming'); // ['upcoming', 'previous'
  /* react-query */
  const { isLoading, data } = useQuery('getUserBookings', async () => getUserBookings());

  useEffect(() => {
    document.title = t('title.bookingList');
    if (!isLoading && data) {
      const sortByIdBooking = (a: any, b: any) => b.idBooking - a.idBooking;
      const sortByUpcomingDate = (a: any, b: any) => {
        // Si une des dates est null, la mettre à la fin
        if (!a.dateBooking) return 1;
        if (!b.dateBooking) return -1;
        const dateA = new Date(a.dateBooking).getTime();
        const dateB = new Date(b.dateBooking).getTime();
        return dateA - dateB;
      };

      // Filter out cancelled bookings (status 9) from upcoming and add them to previous
      const cancelledBookings = data.data.upcomingBooking.filter((booking: any) => booking.status === 9);
      const upcoming = data.data.upcomingBooking
        .filter((booking: any) => booking.status !== 9)
        .sort(sortByUpcomingDate);

      // Combine previous bookings with cancelled bookings
      const previous = [...data.data.previousBooking, ...cancelledBookings].sort(sortByIdBooking);

      // Redirection si un seul rendez-vous à venir et pas de précédents
      if (upcoming.length === 1 && previous.length === 0) {
        localStorage.setItem('silkke_single_booking', 'true');
        navigate(`/booking/${upcoming[0].idBooking}`);
        return;
      }

      setUpcomingBookingList(upcoming);
      setPreviousBookingList(previous);

      // On ne bascule automatiquement que lors du chargement initial
      if (upcoming.length === 0 && previous.length > 0) {
        setBookingTime('previous');
      }

      setLoading(false);
    }
  }, [isLoading, data, navigate, t]);

  return (
    <style.Container>
      <Header title="Book" subTitle="capsule" />
      {isLoading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <style.Booking>
          <style.NewBooking size={screenSize}>
            <style.NewBookingButton size={screenSize} to="/booking/new">
              {t('booking.add')}
            </style.NewBookingButton>
          </style.NewBooking>
          {(upcomingBookingList.length > 0
            || previousBookingList.length > 0) && (
              <style.BookingTime size={screenSize}>
                <style.BookingTimeButton
                  size={screenSize}
                  onClick={() => setBookingTime('upcoming')}
                  type="button"
                  selected={bookingTime === 'upcoming'}
                >
                  {t('booking.upcoming')}
                </style.BookingTimeButton>
                <style.BookingTimeButton
                  size={screenSize}
                  onClick={() => setBookingTime('previous')}
                  type="button"
                  selected={bookingTime === 'previous'}
                >
                  {t('booking.previous')}
                </style.BookingTimeButton>
              </style.BookingTime>
          )}

          <style.BookingList size={screenSize}>
            {bookingTime === 'upcoming' ? (
              upcomingBookingList.length > 0 ? (
                <List bookList={upcomingBookingList} isPrevious={false} />
              ) : (
                <style.EmptyMessage size={screenSize}>
                  {t('booking.no.upcoming')}
                </style.EmptyMessage>
              )
            ) : previousBookingList.length > 0 ? (
              <List bookList={previousBookingList} isPrevious />
            ) : (
              <style.EmptyMessage size={screenSize}>
                {t('booking.no.previous')}
              </style.EmptyMessage>
            )}
          </style.BookingList>
        </style.Booking>
      )}
    </style.Container>
  );
};

export default BookingList;
