import { useState, useEffect } from 'react';
import { getEyesForm, getHairForm } from 'providers/style';

export type StyleSelections = {
  eyesIds: number[];
  hairIds: number[];
  hairColorsIds: number[];
};

type BuildYourStyleHookProps = {
  onComplete?: (selections: StyleSelections) => void;
  initialStep?: 'style' | 'hair';
  skipPreview?: boolean;
};

type BuildYourStyleHookReturn = {
  // États
  globalStep: 'style' | 'hair' | 'submission';
  eyesIds: number[] | undefined;
  hairColorsIds: number[] | undefined;
  hairIds: number[] | undefined;
  mustSkipPreview: boolean;
  isComplete: boolean;

  // Actions
  setEyesIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setHairColorsIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setHairIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setGlobalStep: React.Dispatch<
  React.SetStateAction<'style' | 'hair' | 'submission'>
  >;
  changeStepHairToStyle: () => void;

  // Données complètes
  selections: StyleSelections;
};

const useBuildYourStyle = ({
  onComplete,
  initialStep = 'style',
  skipPreview = false,
}: BuildYourStyleHookProps = {}): BuildYourStyleHookReturn => {
  const [globalStep, setGlobalStep] = useState<'style' | 'hair' | 'submission'>(
    initialStep,
  );
  const [eyesIds, setEyesIds] = useState<number[]>();
  const [hairColorsIds, setHairColorsIds] = useState<number[]>();
  const [hairIds, setHairIds] = useState<number[]>();
  const [mustSkipPreview, setMustSkipPreview] = useState<boolean>(skipPreview);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const changeStepHairToStyle = () => {
    setMustSkipPreview(true);
    setGlobalStep('style');
  };

  // Construire l'objet de sélections à retourner
  const selections: StyleSelections = {
    eyesIds: eyesIds || [],
    hairIds: hairIds || [],
    hairColorsIds: hairColorsIds || [],
  };

  useEffect(() => {
    if (Array.isArray(eyesIds) && eyesIds.length === 3) {
      setGlobalStep('hair');
    }

    // Vérifier si toutes les sélections sont complètes
    if (
      Array.isArray(eyesIds)
      && eyesIds.length === 3
      && Array.isArray(hairColorsIds)
      && hairColorsIds.length === 3
      && Array.isArray(hairIds)
      && hairIds.length > 0
    ) {
      setIsComplete(true);
      if (onComplete) {
        onComplete(selections);
      }
    }
  }, [eyesIds, hairColorsIds, hairIds]);

  return {
    globalStep,
    eyesIds,
    hairColorsIds,
    hairIds,
    mustSkipPreview,
    isComplete,
    setEyesIds,
    setHairColorsIds,
    setHairIds,
    setGlobalStep,
    changeStepHairToStyle,
    selections,
  };
};

export default useBuildYourStyle;
